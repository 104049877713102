import css from 'styled-jsx/css';

const styles = css`
  .benefit-cont {
    .benefit-img {
      padding-bottom: 24px;
    }
    .benefit-texts-cont {
      .benefit-desc-cont {
        margin: 8px 0;
      }
    }
  }
`;

export default styles;
