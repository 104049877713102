import COLORS from '@utils/colors';
import { BREAKPOINT } from '@utils/constants';
import css from 'styled-jsx/css';

const styles = css`
  .taiwan-featured-neighbors-section {
    background: ${COLORS.black100};
    padding: 32px 16px;
    @media screen and (min-width: ${BREAKPOINT.desktop}px) {
      padding: 64px 24px;
    }
    .contents-cont {
      max-width: 1280px;
      margin: 0 auto;
      .title-cont {
        display: flex;
        justify-content: center;
        .title-text {
          border-bottom: 4px solid ${COLORS.purple600};
        }
      }
      .cards-cont {
        margin-top: 32px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        @media screen and (min-width: ${BREAKPOINT.desktop}px) {
          margin-top: 40px;
          flex-direction: row;
          align-items: initial;
        }
      }
    }
  }
`;

export default styles;
