import Button from '@atoms/Button';
import { TypoXLBold } from '@atoms/Typos';
import { Desktop, Mobile } from '@components/Layout';
import NeighborPlans from '@molecules/NeighborPlans';
import routes from '@utils/routes';
import Image from 'next/image';
import useTranslation from 'next-translate/useTranslation';
import styles from './css';

const ConvertSection = () => {
  const { t } = useTranslation('top');

  return (
    <section className="convert-section">
      <div className="background-image-cont">
        <Desktop>
          <Image
            alt="background"
            height={500}
            src="/images/top/convert-section/background-desktop.png"
            style={{
              width: '100%',
            }}
            width={1440}
          />
        </Desktop>
        <Mobile>
          <Image
            alt="background"
            className="backgroun-image"
            fill={true}
            src="/images/top/convert-section/background-mobile.png"
            style={{
              width: '100%',
            }}
          />
        </Mobile>
      </div>
      <div className="content-cont">
        <h2>
          <TypoXLBold color="purple800" text={t('convertSection.title')} />
        </h2>
        <div className="neighbor-plans-cont">
          <NeighborPlans
            showAboutCoin={false}
            showLabel={false}
            showNotice={false}
          />
        </div>
        <div className="cta-button-cont">
          <Button
            label={t('convertSection.ctaButtonLabel')}
            link={routes.signup}
            size="large"
          />
        </div>
      </div>
      <style jsx={true}>{styles}</style>
    </section>
  );
};

export default ConvertSection;
