import COLORS from '@utils/colors';
import { BREAKPOINT } from '@utils/constants';
import css from 'styled-jsx/css';

const styles = css`
  .hafh-guide {
    background-color: ${COLORS.black100};
    .section-inner {
      width: 100%;
      padding: 56px 16px;
      margin: auto;
      @media screen and (min-width: ${BREAKPOINT.desktop}px) {
        max-width: 960px;
        padding: 56px 30px;
      }
      .title {
        text-align: center;
        margin: 0 auto 32px;
      }
    }
    .card-setcion {
      border-radius: 8px;
      border: 1px solid ${COLORS.black200};
      overflow: hidden;
      @media screen and (min-width: ${BREAKPOINT.desktop}px) {
        display: flex;
      }
    }
    .card-image {
      background-image: url('/images/lp/top/guide-banner.png');
      background-size: cover;
      background-position: center;
      height: 160px;
      @media screen and (min-width: ${BREAKPOINT.mobileS}px) {
        height: 280px;
      }
      @media screen and (min-width: ${BREAKPOINT.desktop}px) {
        width: 240px;
        height: 192px;
      }
    }
    .card-body {
      padding: 16px;
      background-color: ${COLORS.white};
      @media screen and (min-width: ${BREAKPOINT.desktop}px) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
        padding: 16px 24px;
      }
      .divider {
        margin-top: 8px;
        @media screen and (min-width: ${BREAKPOINT.desktop}px) {
          margin-top: 12px;
        }
      }
    }
    .card-cta {
      display: flex;
      justify-content: flex-end;
      margin-top: 16px;
      @media screen and (min-width: ${BREAKPOINT.desktop}px) {
        margin-top: 0;
      }
    }
  }
`;

export default styles;
