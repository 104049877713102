import css from 'styled-jsx/css';

const styles = css`
  .featured-neighbor-card {
    border-radius: 8px;
    box-shadow:
      0px 2px 4px -2px rgba(24, 39, 75, 0.12),
      0px 4px 4px -2px rgba(24, 39, 75, 0.08);
    max-width: 400px;
    width: 100%;
    overflow: hidden;
    .content-cont {
      padding: 16px;
      .title {
        margin: 4px 0 8px;
      }
      .desc {
        :global(span) {
          word-break: break-word;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          white-space: normal;
          -webkit-line-clamp: 3;
        }
      }
    }
  }
`;

export default styles;
