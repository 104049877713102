import AutoSuggestInHeader from '@molecules/AutoSuggestInHeader';
import BenefitsSection from '@routes/index/components/BenefitsSection';
import ConvertSection from '@routes/index/components/ConvertSection';
import PopularPropertiesSection from '@routes/index/components/PopularPropertiesSection';
import TaiwanFeaturedNeighborsSection from '@routes/index/components/TaiwanFeaturedNeighborsSection';
import TopHeroSection from '@routes/index/components/TopHeroSection';

const IndexView = () => (
  <div>
    <TopHeroSection />
    <AutoSuggestInHeader hasBackground={true} />
    <PopularPropertiesSection />
    <BenefitsSection />
    <ConvertSection />
    <TaiwanFeaturedNeighborsSection />
  </div>
);

export default IndexView;
