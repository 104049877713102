import COLORS from '@utils/colors';
import {
  BREAKPOINT,
  HEADER_HEIGHT_DESKTOP,
  HEADER_HEIGHT_MOBILE,
} from '@utils/constants';
import css from 'styled-jsx/css';

const inboxHeaderHeight = 95;

const alertHeightMobile = 60;
const alertHeightDesktop = 42;

const styles = css`
  .page {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    @supports (-webkit-touch-callout: none) {
      min-height: -webkit-fill-available;
    }
    &.with-header {
      padding-top: ${HEADER_HEIGHT_MOBILE}px;
      @media screen and (min-width: ${BREAKPOINT.desktop}px) {
        padding-top: ${HEADER_HEIGHT_DESKTOP - 1}px;
      }
    }
    &.with-bottom-nav {
      padding-bottom: 60px;
      @media screen and (min-width: ${BREAKPOINT.desktop}px) {
        padding-bottom: 0;
      }
    }
    :global(.welcome-back-header) {
      width: 100%;
      position: sticky;
      z-index: 9;
      top: ${HEADER_HEIGHT_MOBILE}px;
      @media screen and (min-width: ${BREAKPOINT.desktop}px) {
        top: ${HEADER_HEIGHT_DESKTOP}px;
      }
      .welcome-back-snooze {
        background-color: ${COLORS.purple600};
        display: flex;
        align-items: center;
        padding: 10px 16px;
        justify-content: space-between;
        @media screen and (min-width: ${BREAKPOINT.desktop}px) {
          justify-content: center;
          gap: 8px;
        }
        :global(a) {
          margin: 0;
        }
      }
    }
    .page-body {
      :global(.inbox-detail) {
        height: calc(100vh - ${HEADER_HEIGHT_MOBILE}px);

        @media screen and (min-width: ${BREAKPOINT.desktop}px) {
          height: calc(100vh - ${HEADER_HEIGHT_DESKTOP}px);
        }
      }

      :global(.inbox-list-container) {
        @media screen and (max-width: ${BREAKPOINT.mobile}px) {
          min-height: calc(
            100vh - (${HEADER_HEIGHT_MOBILE}px + ${inboxHeaderHeight}px)
          );
        }

        @media screen and (min-width: ${BREAKPOINT.desktop}px) {
          height: calc(
            100vh - (${HEADER_HEIGHT_DESKTOP}px + ${inboxHeaderHeight}px)
          );
        }
      }

      &.with-alert {
        :global(.inbox-detail) {
          height: calc(
            100vh - (${HEADER_HEIGHT_MOBILE}px + ${alertHeightMobile}px)
          );

          @media screen and (min-width: ${BREAKPOINT.desktop}px) {
            height: calc(
              100vh - (${HEADER_HEIGHT_DESKTOP}px + ${alertHeightDesktop}px)
            );
          }
        }

        :global(.inbox-list-container) {
          @media screen and (min-width: ${BREAKPOINT.desktop}px) {
            height: calc(
              100vh -
                (
                  ${HEADER_HEIGHT_DESKTOP}px + ${inboxHeaderHeight}px +
                    ${alertHeightDesktop}px
                )
            );
          }
        }
      }
    }
    &.black100 {
      background-color: ${COLORS.black100};
    }
  }
`;

export default styles;
