/* eslint-disable */
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { SwiperSlide } from 'swiper/react';

import { Desktop, Mobile } from '@components/Layout';

import Button from '@atoms/Button';
import Dialog from '@atoms/Dialog';
import HafhCoinDialogButton from '@atoms/HafhCoinDialogButton';
import NeighborPlan from '@atoms/NeighborPlan';
import Swiper from '@atoms/Swiper';
import { TypoLBold, TypoMBold, TypoXLBold, TypoXS } from '@atoms/Typos';

import NeighborTestimonialDialogContent from '@molecules/NeighborTestimonialDialogContent';

import { useAppSelector } from '@hooks/useAppSelector';
import { JAL_PLAN_CODES, TAIWAN_SNOOZE_CODE } from '@utils/constants';
import { useRecommendedPlan, useIsDiscount } from '@utils/hooks';
import routes from '@utils/routes';
import { isNewNeighborPlanCodeForJapan } from '@utils/validation';

import { NeighborPlan as NeighborPlanType } from '@services/hafh/types/generated';

import styles from './css';
import useLocation from '@hooks/useLocation';
import useRegistrationCountry from '@hooks/useRegistrationCountry';

type Props = {
  showTitle?: boolean;
  selectedPlanId?: number;
  onSelectPlan?: (
    neighborPlan: NeighborPlanType | null,
    promotionPlan: NeighborPlanType | null
  ) => void;
  disableCondition?: (plan: any) => string;
  showFirstMonthDiscount?: boolean;
  showOthersByDefault?: boolean;
  showNotice?: boolean;
  showAboutCoin?: boolean;
  mobileLayout?: 'vertical' | 'slider';
  minSelectablePlanValue?: number;
  showNewPlans?: boolean;
  showLabel?: boolean;
  showReferralCodeBonus?: boolean;
  changeablePlans?: Array<NeighborPlanType> | null | undefined;
};
const options = {
  slidesPerView: 'auto',
  spaceBetween: 16,
  centeredSlides: true,
} as const;

const NeighborPlans = ({
  showTitle = false,
  selectedPlanId,
  onSelectPlan,
  disableCondition,
  showFirstMonthDiscount = true,
  showOthersByDefault = true,
  showNotice = true,
  showAboutCoin = true,
  mobileLayout = 'vertical',
  minSelectablePlanValue = 0,
  showNewPlans = true,
  showReferralCodeBonus = false,
  showLabel = true,
  changeablePlans,
}: Props) => {
  const { t } = useTranslation('neighbor-plan');
  const { isJapan } = useLocation();
  const { isRegistrationCountryJapan, isRegistrationCountryTaiwan } =
    useRegistrationCountry();
  const { neighborPlans, neighbor } = useAppSelector((state) => ({
    neighborPlans: state.neighborPlans,
    neighbor: state.neighbor,
  }));
  const { pathname } = useRouter();
  const [showOthers, setShowOthers] = useState(showOthersByDefault);
  const [dialogKey, setDialogKey] = useState<string | null>(null);

  const isPartnerPlanMode =
    neighborPlans.length === 1 && neighborPlans[0].campaign;

  const neighborPlansToShowGeneral = neighborPlans
    .filter((plan) => plan !== undefined)
    .filter((plan) => plan.kind !== 'snooze')
    .filter((plan) => plan.monthly_price >= minSelectablePlanValue);

  const neighborPlansToShowJapan = neighborPlansToShowGeneral
    .filter((plan) => plan.campaign || !JAL_PLAN_CODES.includes(plan.code))
    .filter(
      (plan) =>
        showNewPlans ||
        !isNewNeighborPlanCodeForJapan(plan.code) ||
        plan.campaign
    )
    .filter((plan) => isNewNeighborPlanCodeForJapan(plan.code));

  const neighborPlansToShow = (() => {
    if (neighbor) {
      return isRegistrationCountryJapan
        ? neighborPlansToShowJapan
        : neighborPlansToShowGeneral;
    }
    return isJapan ? neighborPlansToShowJapan : neighborPlansToShowGeneral;
  })();

  const orderedNeighborPlansToShow = neighborPlansToShow.sort((a, b) => {
    return Number(b.recommend) - Number(a.recommend);
  });

  const recommendedPlan = useRecommendedPlan();

  const showNoticeText = () => {
    if (isRegistrationCountryTaiwan && isPartnerPlanMode) {
      return true;
    }
    return showNotice;
  };

  const showDiscountPlanText = () => {
    if (isRegistrationCountryTaiwan && isPartnerPlanMode) {
      return false;
    }
    return !!recommendedPlan;
  };

  useEffect(() => {
    if (
      (!isJapan &&
        !selectedPlanId &&
        onSelectPlan &&
        pathname === routes.profileCreate) ||
      isPartnerPlanMode
    ) {
      onSelectPlan && onSelectPlan(orderedNeighborPlansToShow[0], null);
    }
  }, [orderedNeighborPlansToShow]);

  const renderNeighborPlan = (plan: NeighborPlanType) => {
    const changeablePlan =
      changeablePlans && changeablePlans.find((cp) => cp.id === plan.id);

    return (
      <NeighborPlan
        plan={plan}
        changeablePlan={changeablePlan}
        isSelected={plan.id === selectedPlanId}
        onSelectPlan={onSelectPlan}
        disabledLabelKey={disableCondition && disableCondition(plan)}
        showFirstMonthDiscount={showFirstMonthDiscount}
        showReferralCodeBonus={showReferralCodeBonus}
        showLabel={showLabel}
      />
    );
  };

  const createContentText = () => {
    if (isRegistrationCountryTaiwan) {
      return t('notice.taiwanContent');
    }
    return t('notice.content', {
      partner: isPartnerPlanMode ? '無料期間終了後は、' : '',
    });
  };

  return (
    <div className="neighbor-plans-container">
      {showTitle && (
        <h2 className="title">
          <Mobile>
            <TypoLBold text={t('title')} />
          </Mobile>
          <Desktop>
            <TypoXLBold text={t('title')} />
          </Desktop>
        </h2>
      )}

      <div className="neighbor-plans">
        <Mobile>
          {mobileLayout === 'slider' ? (
            <Swiper
              name="plan"
              options={options}
              hasPagination
              hasSwiperButton={false}
            >
              {neighborPlansToShow.map((plan) => (
                <SwiperSlide
                  className="neighbor-plan swiper-slide"
                  key={plan.id}
                >
                  {renderNeighborPlan(plan)}
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            neighborPlansToShow.map((plan) => (
              <div className="neighbor-plan" key={plan.id}>
                {renderNeighborPlan(plan)}
              </div>
            ))
          )}
        </Mobile>
        <Desktop>
          {orderedNeighborPlansToShow.map((plan) => (
            <div className="neighbor-plan" key={plan.id}>
              {renderNeighborPlan(plan)}
            </div>
          ))}
        </Desktop>
      </div>

      {!showOthers && (
        <div className="button-container">
          <Button
            label={t('otherPlans')}
            isSecondary
            onClick={() => setShowOthers(true)}
          />
        </div>
      )}

      {showAboutCoin && <HafhCoinDialogButton />}

      {showNoticeText() && (
        <div className="plan-notice">
          <div className="notice-heading">
            <TypoMBold>{t('notice.title')}</TypoMBold>
          </div>
          <p className="notice-description">
            <TypoXS>{createContentText()}</TypoXS>
          </p>
          {showDiscountPlanText() && (
            <p className="notice-description">
              <TypoXS>
                {t('notice.discountPlan', {
                  planName: recommendedPlan!.name,
                  currencySymbol: recommendedPlan!.currency.symbol,
                  firstMonthPrice:
                    recommendedPlan!.first_month_price.toLocaleString(),
                })}
              </TypoXS>
            </p>
          )}
        </div>
      )}

      <Dialog show={!!dialogKey} onClickButton={() => setDialogKey(null)}>
        <NeighborTestimonialDialogContent planCode={dialogKey} />
      </Dialog>

      <style jsx>{styles}</style>
    </div>
  );
};

export default NeighborPlans;
