import COLORS from '@utils/colors';
import { BREAKPOINT } from '@utils/constants';
import css from 'styled-jsx/css';

const styles = css`
  .benefits-section {
    background: ${COLORS.purple800};
    padding: 48px 16px;
    text-align: center;
    @media screen and (min-width: ${BREAKPOINT.desktop}px) {
      padding: 64px 24px;
    }
    .title {
      margin-bottom: 16px;
    }
    .benefits-cont {
      max-width: 1212px;
      display: flex;
      gap: 48px;
      margin: 64px auto 0;
      flex-direction: column;
      @media screen and (min-width: ${BREAKPOINT.desktop}px) {
        flex-direction: row;
      }
    }
  }
`;

export default styles;
