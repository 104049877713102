import { TypoXLBold } from '@atoms/Typos';
import { useAppSelector } from '@hooks/useAppSelector';
import PropertySlider from '@molecules/PropertySlider';
import TravelStorySlider from '@molecules/TravelStorySlider';
import * as usedCoinsRankingActions from '@redux/modules/usedCoinsRanking';
import { useActions } from '@utils/hooks';
import useTranslation from 'next-translate/useTranslation';
import styles from './css';

const PopularPropertiesSection = () => {
  const { t } = useTranslation('top');

  const { usedCoinsRanking } = useAppSelector((state) => ({
    usedCoinsRanking: state.usedCoinsRanking,
  }));
  const { getUsedCoinsRanking } = useActions({
    ...usedCoinsRankingActions,
  });

  return (
    <section className="popular-properties-section">
      <div className="contents-cont">
        <div className="title-cont">
          <h2 className="title-text">
            <TypoXLBold text={t('popularPropertiesSection.title')} />
          </h2>
        </div>
        {!!usedCoinsRanking?.length && (
          <div className="popular-properties-slider-cont">
            <PropertySlider
              getPropertiesByArea={getUsedCoinsRanking}
              isAreaTabs={true}
              name="used-coin-ranking"
              properties={usedCoinsRanking}
              sliderType="ranking"
              title={t('popularPropertiesSection.monthlyRankingTitle')}
            />
          </div>
        )}
        <TravelStorySlider type="popular" />
      </div>
      <style jsx={true}>{styles}</style>
    </section>
  );
};

export default PopularPropertiesSection;
