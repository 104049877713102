import React from 'react';
import Button from '@atoms/Button';
import { TypoLBold, TypoMBold, TypoS } from '@atoms/Typos';
import routes from '@utils/routes';
import styles from './css';

const HafhGuide = () => (
  <section className="hafh-guide">
    <div className="section-inner">
      <div className="title">
        <TypoLBold text="はじめての方はこちら" />
      </div>
      <div className="card-setcion">
        <div className="card-image" />
        <div className="card-body">
          <div className="card-text-section">
            <TypoMBold tag="p" text="HafHはじめてガイド" />
            <div className="divider" />
            <TypoS
              tag="p"
              text="はじめてHafHをご利用される方や、HafHについて知りたい方のためのガイドページです。HafHの特徴やプラン、お得な使い方などについてご紹介します。"
            />
          </div>
          <div className="card-cta">
            <Button
              label="HafHについて詳しく知る"
              link={routes.guide}
              position="right"
            />
          </div>
        </div>
      </div>
    </div>
    <style jsx={true}>{styles}</style>
  </section>
);

export default HafhGuide;
