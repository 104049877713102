import { useEffect } from 'react';
import { useAppSelector } from '@hooks/useAppSelector';
import useLocation from '@hooks/useLocation';
import IndexView from '@routes/index/view/IndexView';
import JpTopMain from '@templates/JpTopMain';
import routes from '@utils/routes';
import { isEmpty } from '@utils/utils';
import { useRouter } from 'next/router';

const IndexContainer = () => {
  const router = useRouter();
  const { isJapan } = useLocation();
  const { loadingAuthUser, loadingNeighbor, neighbor } = useAppSelector(
    (state) => ({
      loadingAuthUser: state.loadingAuthUser,
      loadingNeighbor: state.loadingNeighbor,
      neighbor: state.neighbor,
    })
  );

  useEffect(() => {
    if (!loadingAuthUser && !loadingNeighbor && !isEmpty(neighbor)) {
      router.replace(routes.browse);
    }
  }, [loadingAuthUser, loadingNeighbor]);

  return isJapan ? <JpTopMain /> : <IndexView />;
};

export default IndexContainer;
