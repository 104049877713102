import { TypoXLBold } from '@atoms/Typos';
import { Desktop, Mobile } from '@components/Layout';
import { useIsTWLunarNewYearCampaignActive } from '@features/campaigns/tw-lunar-new-year/hooks';
import Image from 'next/image';
import useTranslation from 'next-translate/useTranslation';
import styles from './css';

const TopHeroSection = () => {
  const { t } = useTranslation('top');

  const isTWLunarNewYearCampaignActive = useIsTWLunarNewYearCampaignActive();

  if (isTWLunarNewYearCampaignActive) {
    return (
      <section className="top-hero-section">
        <div className="hero-background-img">
          <Desktop>
            <Image
              alt={t('heroSection.title')}
              height={500}
              src="/images/campaign/tw-lunar-new-year/hero-desktop.png"
              style={{
                height: 'auto',
                width: '100%',
              }}
              width={1440}
            />
          </Desktop>
          <Mobile>
            <Image
              alt={t('heroSection.title')}
              height={366}
              src="/images/campaign/tw-lunar-new-year/hero-mobile.png"
              style={{
                height: 'auto',
                width: '100%',
              }}
              width={375}
            />
          </Mobile>
        </div>
        <style jsx={true}>{styles}</style>
      </section>
    );
  }

  return (
    <section className="top-hero-section">
      <div className="hero-background-img">
        <Desktop>
          <Image
            alt={t('heroSection.title')}
            height={500}
            src="/images/top/top-hero-desktop.png"
            style={{
              height: 'auto',
              width: '100%',
            }}
            width={1440}
          />
        </Desktop>
        <Mobile>
          <Image
            alt={t('heroSection.title')}
            height={366}
            src="/images/top/top-hero-mobile.png"
            style={{
              height: 'auto',
              width: '100%',
            }}
            width={375}
          />
        </Mobile>
      </div>
      <div className="hero-title">
        <TypoXLBold color="purple800" text={t('heroSection.title')} />
      </div>
      <style jsx={true}>{styles}</style>
    </section>
  );
};

export default TopHeroSection;
