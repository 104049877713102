import { User } from 'firebase/auth';
import { Moment } from 'moment-timezone';
import { Action, Dispatch } from 'redux';
import apiClient, { API_TYPES } from '@services/hafh/api';
import { PropertyPlansCheckReservable } from '@services/hafh/types/generated';
import { LANG_LOCALE } from '@utils/types';

const api = apiClient(API_TYPES.API);

type checkReservableState = PropertyPlansCheckReservable & {
  isLoadingCheckReservable: boolean;
};

const initialState: {
  checkReservable: checkReservableState;
  // TODO update to use vacancy swagger type
  vacancy: any;
} = {
  checkReservable: {
    adjustment_coin: null,
    can_use_payment_options: null,
    isLoadingCheckReservable: false,
    min_coin_per_night: {
      base: 0,
      vip: null,
    },
    purchase_coin_limit: {
      amount: null,
      coin: null,
      currency: {
        code: '',
        decimal_point: 0,
        symbol: '',
      },
    },
    total_coin: null,
    vip: {
      discount_rate: 0,
      rank: null,
    },
    vip_total_coin: null,
  },
  vacancy: {},
};

export const UPDATE_ROOM_TYPE_VACANCY =
  'hafh/propertyPlanVacancy/UPDATE_ROOM_TYPE_VACANCY' as const;

export const UPDATE_CHECK_RESERVABLE =
  'hafh/propertyPlanVacancy/UPDATE_CHECK_RESERVABLE' as const;

export const UPDATE_IS_LOADING_CHECK_RESERVABLE =
  'hafh/propertyPlanVacancy/UPDATE_IS_LOADING_CHECK_RESERVABLE' as const;

const RoomTypes = (
  state = initialState,
  // TODO: implement Swagger
  action:
    | ReturnType<typeof updateRoomTypeVacancy>
    | ReturnType<typeof updateCheckReservable>
    | ReturnType<typeof updateIsLoadingCheckReservable>
) => {
  switch (action.type) {
    case UPDATE_ROOM_TYPE_VACANCY: {
      return {
        ...state,
        vacancy: action.payload,
      };
    }

    case UPDATE_CHECK_RESERVABLE: {
      return {
        ...state,
        checkReservable: {
          ...state.checkReservable,
          ...action.payload,
        },
      };
    }

    case UPDATE_IS_LOADING_CHECK_RESERVABLE: {
      return {
        ...state,
        checkReservable: {
          ...state.checkReservable,
          isLoadingCheckReservable: action.payload,
        },
      };
    }

    default: {
      return state;
    }
  }
};

export const updateRoomTypeVacancy = (roomTypeVacancy = {}) => ({
  payload: roomTypeVacancy,
  type: UPDATE_ROOM_TYPE_VACANCY,
});

export const updateCheckReservable = (
  // TODO: use updated swagger
  checkReservable: PropertyPlansCheckReservable
) => ({
  payload: checkReservable,
  type: UPDATE_CHECK_RESERVABLE,
});

export const updateIsLoadingCheckReservable = (
  isLoadingCheckReservable: boolean
) => ({
  payload: isLoadingCheckReservable,
  type: UPDATE_IS_LOADING_CHECK_RESERVABLE,
});

export const getRoomTypesVacancy =
  (roomTypeId: number) => async (dispatch: Dispatch<Action>) => {
    const roomTypeVacancy = await api.get(
      `room_types/${roomTypeId}/vacancy`,
      {},
      ''
    );

    if (roomTypeVacancy) {
      dispatch(updateRoomTypeVacancy(roomTypeVacancy));

      return roomTypeVacancy;
    }
  };

export const getCheckReservable =
  (
    {
      checkin,
      checkout,
      guestNumber,
      isSplitBooking,
      roomTypeId,
    }: {
      checkin?: Moment;
      checkout?: Moment;
      guestNumber: number;
      isSplitBooking?: boolean;
      roomTypeId: Array<number>;
    },
    locale: LANG_LOCALE,
    authUser: User
  ) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(updateIsLoadingCheckReservable(true));
    const checkReservable: PropertyPlansCheckReservable = await api.get(
      'property_plans/check_reservable',
      {
        check_in_date: checkin,
        check_out_date: checkout,
        guest_number: guestNumber,
        is_split_reservation: isSplitBooking,
        room_type_id: roomTypeId,
      },
      locale,
      authUser
    );

    if (checkReservable) {
      dispatch(updateCheckReservable(checkReservable));
    }

    return dispatch(updateIsLoadingCheckReservable(false));
  };

export default RoomTypes;
