export type TaiwanFeaturedNeighbor = {
  desc: string;
  id: number;
  img: string;
  publication_date: string;
  title: string;
};

export const TAIWAN_FEATURED_NEIGHBORS: Array<TaiwanFeaturedNeighbor> = [
  {
    desc: 'U1 的大學期間選修了關於日本的課程，並且有機會參加了新潟的交換留學計劃。這段難能可貴的經驗，尤其是新潟的豐富觀光資源，如三大煙火、夜櫻，以及神似《神隱少女》中「盆舟」的佐渡島，這些美好的經驗成為了他留在日本的契機。',
    id: 21_149,
    img: '/images/top/taiwan-featured-neighbors-section/featured-neighbor-1.png',
    publication_date: '2024.09.06',
    title:
      'HafH 人物專訪｜日本有個U1 YuiTube：「透過旅行，認識活力滿滿的日本。」',
  },
  {
    desc: '身為廣告代理商創意總監兼普通煩惱自救會 Podcast 主持人的家瑜，與我們分享她當時那股「反正我是去定了」在腦海中隱隱作祟的念頭、即便知道得認命加碼預算卻發現事情（各家浮動價格）並不單純，最終透過 HafH 找到了成行的曙光，而且幸運地搭上了關東櫻花滿開的列車。',

    id: 19_377,
    img: '/images/top/taiwan-featured-neighbors-section/featured-neighbor-2.png',
    publication_date: '2024.05.13',
    title:
      'HafH 人物專訪｜普通煩惱自救會主持人家瑜：「反正我是去定了」—享受任性的賞櫻之旅',
  },
  {
    desc: '長期居住在日本並樂於和大家分享飯店相關資訊的東京飯店通，其實早在數年前就有申辦 HafH 的會籍！而隨著後續疫情趨緩、HafH 的服務更新進化，讓三不五時會遊走在眾多訂房平台的他，對於這次使用全新 HafH 服務入住新開幕的旅宿，在日後旅宿選擇安排上，有了嶄新的體會。',

    id: 19_034,
    img: '/images/top/taiwan-featured-neighbors-section/featured-neighbor-3.png',
    publication_date: '2024.03.21',
    title:
      'HafH 人物專訪｜東京飯店通：「不帶特定目的一泊，享受嶄新視角的東京！」',
  },
];
