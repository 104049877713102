import { BREAKPOINT } from '@utils/constants';
import css from 'styled-jsx/css';

const styles = css`
  .convert-section {
    position: relative;

    .background-image-cont {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      .background-image {
        object-fit: cover;
      }
    }
    .content-cont {
      z-index: 1;

      text-align: center;
      padding: 48px 16px;
      @media screen and (min-width: ${BREAKPOINT.desktop}px) {
        padding: 64px 24px;
      }
      .neighbor-plans-cont {
        margin: 48px 0;
      }
      .cta-button-cont {
        @media screen and (min-width: ${BREAKPOINT.desktop}px) {
          width: 302px;
          margin: auto;
        }
      }

      .cta-button-cont,
      h2 {
        position: relative;
        z-index: 1;
      }
    }
  }
`;

export default styles;
