import { TypoXLBold } from '@atoms/Typos';
import useLocation from '@hooks/useLocation';
import { TAIWAN_FEATURED_NEIGHBORS } from '@routes/index/components/TaiwanFeaturedNeighborsSection/constants';
import TaiwanFeaturedNeighborCard from '@routes/index/components/TaiwanFeaturedNeighborsSection/TaiwanFeaturedNeighborCard';
import useTranslation from 'next-translate/useTranslation';
import styles from './css';

const TaiwanFeaturedNeighborsSection = () => {
  const { t } = useTranslation('top');

  const { isTaiwan } = useLocation();

  if (!isTaiwan) {
    return null;
  }

  return (
    <section className="taiwan-featured-neighbors-section">
      <div className="contents-cont">
        <div className="title-cont">
          <h2 className="title-text">
            <TypoXLBold text={t('taiwanFeaturedNeighborsSection.title')} />
          </h2>
        </div>
        <div className="cards-cont">
          {TAIWAN_FEATURED_NEIGHBORS.map((taiwanFeaturedNeighbor) => (
            <TaiwanFeaturedNeighborCard
              key={taiwanFeaturedNeighbor.id}
              featuredNeighbor={taiwanFeaturedNeighbor}
            />
          ))}
        </div>
      </div>
      <style jsx={true}>{styles}</style>
    </section>
  );
};

export default TaiwanFeaturedNeighborsSection;
