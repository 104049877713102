import apiClient, { API_TYPES } from '@services/hafh/api';
import {
  TravelStory,
  TravelStoryListItem,
} from '@services/hafh/types/generated';
import toast from '@utils/toast';
import { LANG_LOCALE } from '@utils/types';
import { TravelStoryFormValues } from '@utils/types/form';
import { User } from 'firebase/auth';
import { Action, Dispatch } from 'redux';

const api = apiClient(API_TYPES.API);

export type TravelStoriesState = {
  propertyTravelStories: {
    [key: number]: TravelStoryListItem[] | [];
  };
  travelStory: TravelStory | null;
};

const initialState: TravelStoriesState = {
  propertyTravelStories: {},
  travelStory: null,
};

// action types
const UPDATE_TRAVEL_STORY = 'hafh/travelStory/UPDATE_TRAVEL_STORY' as const;
const UPDATE_PROPERTY_TRAVEL_STORIES =
  'hafh/travelStory/UPDATE_PROPERTY_TRAVEL_STORIES' as const;

// reducers
const TravelStories = (
  state = initialState,
  action:
    | ReturnType<typeof updateTravelStory>
    | ReturnType<typeof updatePropertyTravelStories>
): TravelStoriesState => {
  switch (action.type) {
    case UPDATE_TRAVEL_STORY: {
      return {
        ...state,
        travelStory: action.payload.travelStory,
      };
    }

    case UPDATE_PROPERTY_TRAVEL_STORIES: {
      return {
        ...state,
        propertyTravelStories: {
          [action.payload.propertyId]: action.payload.propertyTravelStories,
        },
      };
    }

    default: {
      return state;
    }
  }
};

// actions creators
export const updateTravelStory = (travelStory: TravelStory | null) => ({
  payload: { travelStory },
  type: UPDATE_TRAVEL_STORY,
});

export const updatePropertyTravelStories = (
  propertyTravelStories: TravelStoryListItem[] | [],
  propertyId: number
) => ({
  payload: { propertyId, propertyTravelStories },
  type: UPDATE_PROPERTY_TRAVEL_STORIES,
});

// actions
export const getTravelStory =
  (id: string, locale: LANG_LOCALE, authUser: User, callback?: () => void) =>
  async (dispatch: Dispatch<Action>) => {
    const travelStory = await api.get(
      `travel_stories/${id}`,
      {},
      locale,
      authUser
    );

    if (travelStory) {
      dispatch(updateTravelStory(travelStory));
    }

    callback?.();
  };

export const getPropertyTravelStories =
  (propertyId: number, locale: LANG_LOCALE) =>
  async (dispatch: Dispatch<Action>) => {
    const propertyTravelStories = await api.get(
      `properties/${propertyId}/travel_stories`,
      {},
      locale,
      null
    );

    if (propertyTravelStories) {
      dispatch(updatePropertyTravelStories(propertyTravelStories, propertyId));
    }
  };

export const postTravelStory =
  (
    authUser: User,
    locale: LANG_LOCALE,
    reservationId: number,
    successCallback?: (travelStory: TravelStory) => void
  ) =>
  async (dispatch: Dispatch<Action>) => {
    const travelStory = await api.post(
      'travel_stories',
      {
        reservation_id: reservationId,
      },
      locale,
      authUser
    );

    if (travelStory) {
      dispatch(updateTravelStory(travelStory));
      successCallback?.(travelStory);
    }
  };

export const putTravelStory =
  (
    authUser: User,
    locale: LANG_LOCALE,
    id: number,
    form: TravelStoryFormValues,
    successCallback?: () => void
  ) =>
  async (dispatch: Dispatch<Action>) => {
    const travelStory = await api.put(
      `travel_stories/${id}`,
      form,
      locale,
      authUser
    );

    if (travelStory) {
      dispatch(updateTravelStory(travelStory));
      successCallback?.();
    } else {
      toast.error('error.default', true);
    }
  };

export const updateImage =
  ({
    authUser,
    data,
    id,
    locale,
    successCallback,
    type,
  }: {
    authUser: User;
    data: TravelStoryFormValues;
    id: number;
    locale: LANG_LOCALE;
    successCallback?: () => void;
    type: 'story' | 'stay';
  }) =>
  async (dispatch: Dispatch<Action>) => {
    const suffix = type === 'stay' ? '/stay' : '';
    const travelStory = await api.post(
      `travel_stories/${id}${suffix}/images`,
      data,
      locale,
      authUser
    );

    if (travelStory) {
      dispatch(updateTravelStory(travelStory));
      successCallback?.();
    }
  };

export const deleteImage =
  ({
    authUser,
    id,
    imageId,
    locale,
    type,
  }: {
    authUser: User;
    id: number;
    imageId: number;
    locale: LANG_LOCALE;
    successCallback?: () => void;
    type: 'story' | 'stay';
  }) =>
  async (dispatch: Dispatch<Action>) => {
    const suffix = type === 'stay' ? '/stay' : '';
    const travelStory = await api.delete(
      `travel_stories/${id}${suffix}/images/${imageId}`,
      {},
      locale,
      authUser
    );

    if (travelStory) {
      dispatch(updateTravelStory(travelStory));
    }
  };

export const addLikedTravelStory =
  (authUser: User, locale: LANG_LOCALE, travelStoryId: string) =>
  async (dispatch: Dispatch<Action>) => {
    const likedTravelStory = await api.post(
      'neighbors/travel_story_likes',
      {
        travel_story_id: travelStoryId,
      },
      locale,
      authUser
    );

    if (likedTravelStory) {
      dispatch(updateTravelStory(likedTravelStory));
    }
  };

export const deleteLikedTravelStory =
  (authUser: User, locale: LANG_LOCALE, likeId: string) =>
  async (dispatch: Dispatch<Action>) => {
    const travelStory = await api.delete(
      `neighbors/travel_story_likes/${likeId}`,
      {},
      locale,
      authUser
    );

    if (travelStory) {
      dispatch(updateTravelStory(travelStory));
    }
  };

export default TravelStories;
