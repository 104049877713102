import css from 'styled-jsx/css';

const styles = css`
  .top-hero-section {
    position: relative;
    .hero-title {
      position: absolute;
      top: 6%;
      left: 0;
      right: 0;
      text-align: center;
      padding: 0 24px;
    }
  }
`;

export default styles;
