import COLORS from '@utils/colors';
import { BREAKPOINT } from '@utils/constants';
import css from 'styled-jsx/css';

const styles = css`
  .popular-properties-section {
    padding: 32px 16px;
    @media screen and (min-width: ${BREAKPOINT.desktop}px) {
      padding: 64px 24px;
    }
    background: ${COLORS.black100};
    .contents-cont {
      max-width: 1280px;
      margin: 0 auto;
      .title-cont {
        display: flex;
        justify-content: center;
        .title-text {
          border-bottom: 4px solid ${COLORS.purple600};
        }
      }
      .popular-properties-slider-cont {
        margin: 16px 0 40px;
      }
    }
  }
`;

export default styles;
