import * as signupLimitActions from '@redux/modules/signupLimit';
import * as travelStoriesListsActions from '@redux/modules/travelStoriesLists';
import * as usedCoinsRankingActions from '@redux/modules/usedCoinsRanking';
import reduxWrapper from '@redux/store';
import IndexPage from '@routes/index';
import routes from '@utils/routes';

const Index = () => <IndexPage />;

export const getServerSideProps = reduxWrapper.getServerSideProps(
  (_store) =>
    async ({ locale, req }) => {
      await Promise.all([
        _store.dispatch(signupLimitActions.getSignupLimit(locale)),
        _store.dispatch(usedCoinsRankingActions.getUsedCoinsRanking(locale)),
        _store.dispatch(
          travelStoriesListsActions.getTravelStoriesList('popular', 1, locale)
        ),
      ]);

      const { hafhLoggedIn } = req.cookies;

      if (hafhLoggedIn === 'true') {
        return {
          redirect: {
            destination: `/${locale}/${routes.browse}`,
            permanent: false,
          },
        };
      }
    }
);

export default Index;
