import { TypoLBold, TypoM, TypoS, TypoXLBold } from '@atoms/Typos';
import { Desktop, Mobile } from '@components/Layout';
import Benefit from '@routes/index/components/BenefitsSection/Benefit';
import {
  BENEFITS_TYPES,
  BenefitTypes,
} from '@routes/index/components/BenefitsSection/constants';
import useTranslation from 'next-translate/useTranslation';
import styles from './css';

const BenefitsSection = () => {
  const { t } = useTranslation('top');

  return (
    <section className="benefits-section">
      <div className="header-cont">
        <h2 className="title">
          <Mobile>
            <TypoLBold color="white" text={t('benefitsSection.title')} />
          </Mobile>
          <Desktop>
            <TypoXLBold color="white" text={t('benefitsSection.title')} />
          </Desktop>
        </h2>
        <Mobile>
          <TypoS color="white" text={t('benefitsSection.subTitle')} />
        </Mobile>
        <Desktop>
          <TypoM color="white" text={t('benefitsSection.subTitle')} />
        </Desktop>
      </div>
      <div className="benefits-cont">
        {BENEFITS_TYPES.map((benefitType: BenefitTypes) => (
          <Benefit key={benefitType} type={benefitType} />
        ))}
      </div>
      <style jsx={true}>{styles}</style>
    </section>
  );
};

export default BenefitsSection;
