import COLORS from '@utils/colors';
import css from 'styled-jsx/css';

const styles = css`
  .icon-with-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60px;
    gap: 4px;
    font-weight: bold;
    > :global(span) {
      white-space: nowrap;
    }
    &:not(.active):hover {
      > .icon :global(path) {
        fill: ${COLORS.black700};
      }
      > :global(span) {
        color: ${COLORS.black700} !important;
      }
    }

    .icon {
      width: 24px;
      height: 24px;
    }
  }
`;

export default styles;
