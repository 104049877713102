import { TypoSBold, TypoXS } from '@atoms/Typos';
import Link from '@components/Link';
import { BENEFITS_LINKS } from '@routes/index/components/BenefitsSection/Benefit/constants';
import { BenefitTypes } from '@routes/index/components/BenefitsSection/constants';
import Image from 'next/image';
import useTranslation from 'next-translate/useTranslation';
import styles from './css';

const Benefit = ({ type }: { type: BenefitTypes }) => {
  const { t } = useTranslation('top');

  return (
    <div className="benefit-cont">
      <div className="benefit-img">
        <Image
          alt={type}
          height={100}
          src={`/images/top/benefits/${type}.png`}
          width={100}
        />
      </div>
      <div className="benefit-texts-cont">
        <TypoSBold color="white" text={t(`benefitsSection.${type}.title`)} />
        <div className="benefit-desc-cont">
          <TypoXS color="white" text={t(`benefitsSection.${type}.desc`)} />
        </div>
        {type in BENEFITS_LINKS && (
          <Link href={BENEFITS_LINKS[type as keyof typeof BENEFITS_LINKS]}>
            <TypoXS
              color="white"
              text={t('benefitsSection.learnMore')}
              underline={true}
            />
          </Link>
        )}
      </div>
      <style jsx={true}>{styles}</style>
    </div>
  );
};

export default Benefit;
